var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import SocialForm from './Forms/SocialForm.vue';
import SignUpForm from './Forms/SignUpForm.vue';
import LoginForm from './Forms/LoginForm.vue';
import ValidateForm from './Forms/ValidateForm.vue';
import IconLogoSpeedio from '../Icons/IconLogoSpeedio.vue';
import ModalNewHyperbolic from '../HyperbolicTree/ModalNewHyperbolic.vue';
import * as Sentry from '@sentry/browser';
import { overmind, connect } from '@/overmind';
import ModalExperienceUser from '../HyperbolicTree/ModalExperienceUser.vue';
let SignInForm = class SignInForm extends Vue {
    constructor() {
        super(...arguments);
        this.formTypes = {
            signup: SignUpForm,
            signin: LoginForm,
        };
        this.currentType = 'signin';
        this.validateForm = false;
        this.openModal = false;
        this.btnLoading = false;
        this.engageLink = null;
        this.authData = {
            email: '',
            cellphone: '',
        };
        this.alertMessage = '';
        this.plgData = {};
    }
    setData(data) {
        this.authData = data;
    }
    get isCurrentLoginPage() {
        return this.currentType == 'signin';
    }
    mounted() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const origin = urlParams.get('origin');
        const cupom = urlParams.get('type');
        const campaign = urlParams.get('campaign');
        const channel = urlParams.get('channel');
        const referral = urlParams.get('referral');
        this.engageLink = urlParams.get('engage_link');
        if (origin)
            this.plgData['origin'] = origin;
        if (cupom)
            this.plgData['credit_coupon'] = cupom;
        if (campaign)
            this.plgData['campaign'] = campaign;
        if (channel)
            this.plgData['channel'] = channel;
        else
            this.plgData['channel'] = 'atlas';
        if (referral)
            this.plgData['referral_code'] = referral;
        const token = urlParams.get('token');
        if (token) {
            this.authUser(token);
            return;
        }
        const register = urlParams.get('register');
        if (register) {
            this.switchStance();
        }
    }
    tryToAuth(data) {
        if (data.email) {
            this.validateForm = true;
            this.authData = data;
        }
    }
    switchStance() {
        const otherInstance = this.currentType == 'signup' ? 'signin' : 'signup';
        this.validateForm = false;
        this.currentType = otherInstance;
    }
    async authUser(token) {
        if (token == 'goBack') {
            this.validateForm = false;
            this.authData = {
                email: '',
            };
        }
        else {
            localStorage.setItem('auth_token_default', token);
            //@ts-ignore
            const refresh_token = await this.users.getUserRefreshToken(token);
            if (refresh_token.status == 200) {
                localStorage.setItem('auth_token_default', refresh_token.data.jwt);
                //@ts-ignore
                const me = await this.users.getUserMeByToken();
                this.setLoginSession(me);
                if (this.engageLink) {
                    window.location.href = `/contratacao-client/${this.engageLink}`;
                }
                else {
                    window.location.href = '/search';
                }
            }
            else {
                localStorage.setItem('auth_token_default', '');
            }
        }
    }
    handleAlertMessage(message) {
        this.alertMessage = message;
    }
    handleBtnLoading(state) {
        this.btnLoading = state;
    }
    async authentication(code) {
        this.btnLoading = true;
        await this.$recaptchaLoaded();
        const recaptchaToken = await this.$recaptcha('signin');
        if (this.currentType == 'signin')
            await this.signin(code, recaptchaToken);
        if (this.currentType == 'signup')
            await this.signup(code, recaptchaToken);
        this.btnLoading = false;
    }
    async signin(code, captcha) {
        //@ts-ignore
        const res = await this.users.tryAuthLogin({
            email: this.authData.email,
            code,
            captcha,
        });
        if (res && res.data && res.data.authentication_token) {
            const authToken = res.data.authentication_token;
            this.authUser(authToken);
        }
    }
    async signup(code, captcha) {
        //@ts-ignore
        const res = await this.users.tryCreateNewAccount({
            email: this.authData.email,
            phone: this.authData.cellphone,
            code,
            captcha,
            ...this.plgData,
        });
        if (res && res.data && res.data.authentication_token) {
            const authToken = res.data.authentication_token;
            this.authUser(authToken);
        }
    }
    async setLoginSession(res) {
        const data = res.data.data.attributes;
        const csEmail = data.account_cs ? data.account_cs.cs_email : '';
        if (res) {
            //@ts-ignore
            const currentUser = {
                id: res.data.data.id,
                onboardingRememberMe: data.onboarding_remember_me,
                name: data.name,
                email: data.email,
                role: data.role,
                accountType: data.account_type,
                origin: data.account_origin,
                channel: data.account_channel,
                campaign: data.account_campaign,
                userInFirstMonth: data.user_in_first_month,
                hasOnboarding: data.has_onboarding,
                features: data.features,
                customer_id: data.customer_id,
                superlogica_id: data.superlogica_id,
                featureGroup: data.feature_group,
                csEmail,
                csgpt: data.csgpt,
                canReactivate: data.can_reactivate,
                showSpeedmailApp: data.show_speedmail_app,
            };
            //@ts-ignore
            overmind.actions.users.setCurrentUser(currentUser);
            //@ts-ignore
            await this.search.getSearchHistory();
            //@ts-ignore
            await this.users.setAutoplay();
        }
        const { name, email } = res.data.data.attributes;
        localStorage.removeItem('tokenPipeDrive');
        localStorage.removeItem('tokenPiperun');
        localStorage.removeItem('tokenAgendor');
        localStorage.removeItem('tokenMeetime');
        localStorage.removeItem('tokenPloomes');
        localStorage.removeItem('tokenSalesForce');
        localStorage.removeItem('tokenRdSTation');
        localStorage.removeItem('tokenHubSpot');
        localStorage.removeItem('urlBitrix24');
        localStorage.removeItem('integrationTokenToTvs');
        localStorage.removeItem('accessTokenToTvs');
        localStorage.removeItem('noShowAgainUpgradePlanModalPlg');
        localStorage.removeItem('tokenFunilDeVendas');
        localStorage.removeItem('codSellerFunilDeVendas');
        localStorage.removeItem('codSalesChannelFunilDeVendas');
        localStorage.removeItem('keyCodFunilDeVendas');
        localStorage.removeItem('counterClickToCopy');
        localStorage.removeItem('userEmailLogged');
        Sentry.setUser({ email: email, username: name });
    }
};
SignInForm = __decorate([
    Component(connect(({ actions }) => ({
        users: actions.users,
        search: actions.search,
    }), {
        name: 'SignInForm',
        components: {
            SocialForm,
            SignUpForm,
            ValidateForm,
            IconLogoSpeedio,
            LoginForm,
            ModalNewHyperbolic,
            ModalExperienceUser,
        },
    }))
], SignInForm);
export default SignInForm;
