var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue, Watch, Emit, } from 'vue-property-decorator';
import IconSpeedioSpinner from '@/components/Icons/IconSpeedioSpinner.vue';
import Alert from '../Alert.vue';
import { connect } from '@/overmind';
let SignUpForm = class SignUpForm extends Vue {
    constructor() {
        super(...arguments);
        this.btnLoading = false;
        this.disableFields = true;
        this.errorField = '';
        this.data = {
            email: '',
            cellphone: '',
        };
        this.oldData = {
            email: '',
            cellphone: '',
        };
        this.errorList = {
            emptyFields: {
                message: '<b>Ops!</b> Preencha todos os campos.',
            },
            hasAuth: {
                message: '<b>Usuário já cadastado!</b> Tente com outros dados ou faça login para continuar ',
            },
            general: {
                message: '<b>Ops!</b> Ocorreu um erro, por favor tente novamente.',
            },
        };
    }
    resetError() {
        const phone = this.data.cellphone;
        const email = this.data.email;
        this.disableFields = !(phone && email);
        if (!this.disableFields)
            return (this.errorField = '');
    }
    resetFields() {
        for (let each in this.data) {
            this.oldData[each] = this.data[each];
            this.data[each] = '';
        }
    }
    async handleSignUp() {
        if (this.btnLoading)
            return;
        this.btnLoading = true;
        if (!this.data.email || !this.data.cellphone) {
            this.resetFields();
            return this.setErrorField('emptyFields');
        }
        else {
            this.btnLoading = true;
            return this.emitUserData();
        }
    }
    customErrorText(errors) {
        const errorArr = errors;
        let errorText = errorArr['title'];
        errorText += `<br /><b>${errorArr['description'].split(':')[1]}</b>`;
        this.errorList['customErros'] = { message: errorText };
    }
    emitUserData() {
        return this.data;
    }
    emitOldData() {
        return this.oldData;
    }
    setErrorField(field) {
        this.btnLoading = false;
        return (this.errorField = field);
    }
};
__decorate([
    Prop({ default: {} })
], SignUpForm.prototype, "plgData", void 0);
__decorate([
    Watch('data.email'),
    Watch('data.cellphone')
], SignUpForm.prototype, "resetError", null);
__decorate([
    Emit('data')
], SignUpForm.prototype, "emitUserData", null);
__decorate([
    Emit('oldData')
], SignUpForm.prototype, "emitOldData", null);
__decorate([
    Watch('errorKey')
], SignUpForm.prototype, "setErrorField", null);
SignUpForm = __decorate([
    Component(connect(({ actions }) => ({
        users: actions.users,
    }), {
        name: 'SignUpForm',
        components: {
            Alert,
            IconSpeedioSpinner,
        },
    }))
], SignUpForm);
export default SignUpForm;
